import React, {
  Component
} from 'react'
import ReactDOM from 'react-dom'
import VerifyStudent from './Verify'
import Test from './Test'
import Jumbotron from 'react-bootstrap/Jumbotron'
import NewLogin from '../Auth/NewLogin'
import {
  Redirect
} from 'react-router-dom'
import Http from 'axios'
import Button from 'react-bootstrap/Button'
import ScriptTag from 'react-script-tag'
import TimeSeries from '../Util/TimeSeries'
import Chat from '../Chat/Chat'
import Card from 'react-bootstrap/Card'
import SpeechRecognitionComponent from '../Util/SpeechRecognitionComponent'
import SpeechRecognition from '../Util/SpeechRecognition'
import {useParams} from 'react-router-dom'
function withParams(Component) {
  return props => <Component {...props} params={useParams()} />;
}

/* User Verification process */
class Report extends React.Component {
  constructor(props) {
    super(props);
    var x = new Date();
    this.state = {
      time: x,
      offset: x.getTimezoneOffset(),
      state: "Unset",
      first_name: 'Manu',
      step1text: "Capture",
      step2text: "Update",
      step3text: "Upload ID Card",
      last_name: 'Gupta',
      file: null,
      captured: false,
      face: 'visible',
      battery_state: false,
      battery_level: 5,
      camera_error: "None",
      userMenu: false,
      menu: false,
      isMirroringOn: false,
      status: true,
      testStarted: false,
      testLocation:'/newtest',
      transcript: '',
      storage_state: false,
      users: [],
      user_id: 0,
      isFirefox: false
    };
    this.toggleMenu = this.toggleMenu.bind(this);
    this.toggleUserMenu = this.toggleUserMenu.bind(this);
    this.handleCapture = this.handleCapture.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleInput = this.handleInput.bind(this);
    //this.handleTranscriptChange = this.handleTranscriptChange(this); 
    this.getExam = this.getExam.bind(this);
    this.getUserData = this.getUserData.bind(this);
  }
  handleChange = (event) => {
    let nam = event.target.name;
    let val = event.target.value;
    this.setState({
      [nam]: val
    });
  }
  isFirefox = () => {
        return typeof InstallTrigger !== 'undefined';
  }



  async getUserData(exam_id,user_id) {
    var api;
      api = 'user-api/userua/' + exam_id;
    const response = await Http.get(api);
    this.setState({ users: response.data });
    this.setState({ user_id: user_id });
  }

  componentWillMount() {
    const user = JSON.parse(localStorage.getItem('user'));
    const exam = JSON.parse(localStorage.getItem('exam1'));
    if (user) {
      this.state.user = user;
      this.state.isLogged = true;
      this.state.storage_state = true;
    }
    else if (this.props.user) {
      this.state.user = this.props.user;
      this.state.isLogged = true;
    }
    else {
      console.log("User not available");
      this.state.isLogged = false;
    }
    if (exam)
      this.state.exam = exam;
    else if (this.props.exam)
      this.state.exam = this.props.exam;
    else {
      console.log("Exam not available");
      this.state.exam_found = 0;
    }

  }
    //TODO: To append this admit card additon to previous update message. This line is giving error
    /*Http.defaults.headers.common['Authorization'] = this.state.user.token;
    Http.put('user-api/user/' + this.state.user.id, {
            "admit_card": this.state.fileAdmit,
            "status": this.state.status,
        }, {
            headers: {
                "Content-Type": "application/json"
            }
        })
        .then(res => {
            console.log("User updated Successfully with admit card and status");
        })
        .catch(function(error) {
            errorMsgElement.innerHTML = "Failed to update admit card";
            status.value=error;
            console.log(error);
        });*/
  showPosition = (position) => {
    this.state.lat = position.coords.latitude;
    this.state.lng = position.coords.longitude;
  }

  //Will be called post rendering
  async componentDidMount() {
    const errorMsgElement = document.querySelector('span#errorMsg');
    //data received from profile component
    this.setState({
      status: true,
      testStarted: false,
      camera_state: this.state.user.camera_state,
      network_state: this.state.user.network_state,
      battery_state: this.state.user.battery_state,
      battery_level: this.state.user.battery_level,
      cred: this.state.user.cred,
      ip: this.state.user.ip,
      captured: this.state.user.captured,
      live_pic: this.state.user.live_pic,
      reg_pic: this.state.user.reg_pic
    });
      const user_id=this.props.params.studentId.slice(1); 
      if(this.state.exam) {
        this.getUserData(this.state.exam.id,user_id);
      }
  }
  //Clear the timerID set in ComponentDidUpdate test whether this works or not
  componentWillUnmount() {
   // clearInterval(this.timerID);
   // clearInterval(this.timerCap);
   // document.removeEventListener('keydown', this.keydownHandler);
    this.stopStream();
  }
  stopStream = () => {
    // Check if the stream is active before trying to stop it
  if (window.stream && window.stream.active) {
      // Stop all tracks in the stream
      window.stream.getTracks().forEach(track => {
        track.stop();
      });
    }
  }

  /*
    async componentDidMount() {
        const video = document.getElementById('video');
        const errorMsgElement = document.querySelector('span#errorMsg');
        

        
        const constraints = {
            audio: false,
            video: {
                width: 240,
                height: 320
            }
        };
        try {
            const stream = await navigator.mediaDevices.getUserMedia(constraints);
            window.stream = stream;
            video.srcObject = stream;
      //Will click image once after 5 minutes
    //TODO: May have to remove later
            setTimeout(() => {this.handleCapture() }, 2000);
            this.state.user.camera_state=true;
        } catch (e) {
            console.log(`navigator.getUserMedia error:${e.toString()}`);
            if(`navigator.getUserMedia error:${e.toString()}`.includes("Requested device not found"))
            {
                 errorMsgElement.innerHTML = "Please connect a camera to the computer";
            }
            this.state.user.camera_state=false;
        }
    }
  //Upload the pic of the user
    uploadPic = (e) => {
        const errorMsgElement = document.querySelector('span#errorMsg');

        var data = new FormData();
        this.setState({
            file: URL.createObjectURL(e.target.files[0])
        });
        data.append('file', e.target.files[0]);
        Http.post('user-api/usera', data, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            .then(res => {
            errorMsgElement.innerHTML = res.data;
        if(res.data.includes('not'))
                          errorMsgElement.innerHTML = "Photo Verification Failed";
        else
                          errorMsgElement.innerHTML = "Photo Verified";
            })
            .catch(function(error) {
                console.log(error);
            });

    }*/

  //upload a file code and save the link in DB
  uploadAdmitCard = (e) => {
    const admitMsgElement = document.querySelector('span#admitMsg');
    var data = new FormData();
    data.append('file', e.target.files[0]);
    console.log(e.target.files[0]);
    this.setState({
      fileAdmit: URL.createObjectURL(e.target.files[0])
    });
    console.log(this.state.fileAdmit);
    var api = "exam-api/examac/" + this.state.user.id + "/" + this.state.exam.id;
    Http.put(api, data, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
      .then(res => {
        admitMsgElement.innerHTML = "<span className=\"badge badge-dim alert-success\"> Step 3 Completed</span>";
        var tout, tint, minutes_left;
        if (this.state.user.camera_state && this.state.captured && this.state.left > 0) {
          //decrease every minute
          tint = setInterval(() => {
            document.getElementById("wait").innerHTML = this.state.left + " minutes left";
            if (this.state.left <= 0) {
              document.getElementById("wait").href = this.state.testLocation;
              document.getElementById("wait").innerHTML = "Enter Now";
              clearInterval(tint);
            }
            --this.state.left;
          }, 60000);
        }
      })
      .catch(function (error) {
        this.setState({
          fileAdmit: ""
        });
        admitMsgElement.innerHTML = "<span className=\"alert-warning\">Admit card upload failed</span>";
        console.log(error);
      });
  }
  /*updateState = (isAway) => {
	
      Http.defaults.headers.common['Authorization'] = this.state.user.token;
      Http.put('user-api/user/' + this.state.user.id, {
              "admit_card": this.state.fileAdmit,
              "status": this.state.status,
          }, {
              headers: {
                  "Content-Type": "application/json"
              }
          })
          .then(res => {
              //console.log("User updated Successfully");
          })
  }*/
openfullscreen(elem) {
	    if(elem.requestFullscreen)
		    elem.requestFullscreen();
	    else if(elem.webkitRequestFullscreen)
		    elem.webkitRequestFullscreen();
	    else if(elem.msRequestFullscreen)
		    elem.msRequestFullscreen();
}
  handleCapture = (event) => {
    const video = document.getElementById('video1');
    const canvas = document.getElementById('canvas');
    const capture = document.getElementById('capture');
    //const capture = document.querySelector('#capture');
    const errorMsgElement = document.querySelector('span#errorMsg');
    const timeMsgElement = document.querySelector('span#timeMsg');
    const filename = document.querySelector('input#filename');
    const fname = document.querySelector('input#fname');
    const status = document.querySelector('input#status');
    const step1 = document.getElementById("step1");
    //const listep1=document.getElementById("listep1");
    //const countstep1=document.getElementById("countstep1");
    const step2 = document.getElementById("step2");
    const envstep2 = document.getElementById("envstep2");
    //const countstep2=document.getElementById("countstep2");
    const step3 = document.getElementById("step3");
    var wait = document.getElementById("wait");

    if(this.state.exam.platform=="Flexi")
      wait = document.getElementById("waitother");
    //const listep3=document.getElementById("listep3");
    //const countstep3=document.getElementById("countstep3");
    this.setState({
      captured: this.state.camera_state
    });
    this.openfullscreen(document.documentElement);
    if (!this.state.camera_state) {
      errorMsgElement.innerHTML = "Step 1 failed.<br />"+this.state.camera_error+"<br />. Please message your invigilator with the above error";
      return;
    }

    // Draw image
    var context = canvas.getContext('2d');
    context.drawImage(video, 0, 0, 320, 240);
    errorMsgElement.innerHTML = "Please wait, it takes 5 s to verify";
    var blob, url;


    fname.value = "E" + this.state.exam.id.toString() + "_U" + this.state.user.id.toString() + "_entry";
    console.log(fname.value);

    //const url= canvas.toDataURL('image/png').replace('image/png','image/octet-stream');
    canvas.toBlob(function (blob) {
      var newImg = document.getElementById('imageSrc'),
        url = URL.createObjectURL(blob);
      newImg.src = url;

      var data = new FormData();
      var file = new File([blob], fname.value);
      //var file = new File([blob],filename.value);
      //console.log(file);
      data.append('file', file);
      if (blob) {
        Http.post('user-api/usera', data, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
          .then(res => {
            errorMsgElement.innerHTML = res.data;
            filename.value = res.data.split(' ', 1);
            if (res.data.includes('not')) {
              //status.value="Away";
              errorMsgElement.innerHTML = "Step 1 Failed.<b /> <a href=\"#chat\">Chat with invigilator</a>";
              status.value = "Invalid Photo";
            }
            else {
              //status.value="Present";
              // errorMsgElement.innerHTML = "Photo Valid" + String.fromCharCode("10003");
              status.value = "Photo Valid";
              errorMsgElement.classList.add("badge-outline-success");
              errorMsgElement.classList.remove("badge-outline-danger");
              errorMsgElement.innerHTML = "Step 1 Completed";
            }
            step2.classList.remove("noactive");
            step2.classList.add("active");
            //capture.classList.remove("active");
            //capture.classList.add("noactive");
            envstep2.classList.add("active");
            envstep2.classList.remove("noactive");
            wait.classList.remove("noactive");
            wait.classList.add("active");
          })
          .catch(function (error) {
            errorMsgElement.innerHTML = "Failed to save Photo. Scroll down to resolve.";
            errorMsgElement.innerHTML = error;
            status.value = "Failed to save Photo. Chat with invigilator to resolve";
            status.value = error;
            console.log(error);
            //this.setState({step1text:"Chat with Invigilator"});
          });
      }
      //this.setState({profilePic:blob});         
    });
    var count = 6;
    var t30 = setInterval(() => {
      count = count - 1; timeMsgElement.innerHTML = "<span class=\"badge badge-dim alert-success\">Verifying ..." + count + " seconds left</span>"; if (count <= 0) {
        clearInterval(t30);
        if (!this.state.camera_state)
          timeMsgElement.innerHTML = "<span class=\"badge badge-dim alert-warning\">Step 2 warning: Camera Issue</span>";
        else if (!this.state.isMirroringOn)
          timeMsgElement.innerHTML = "<span class=\"badge badge-dim alert-warning\">Step 2 warning: Mirroring Issue</span>";
        else if (!this.state.battery_state)
          timeMsgElement.innerHTML = "<span class=\"badge badge-dim alert-warning\">Step 2 warning: Battery Issue</span>";
        else if (!this.state.network_state)
          timeMsgElement.innerHTML = "<span class=\"badge badge-dim alert-warning\">Step 2 warning: Network Issue</span>";
        else timeMsgElement.innerHTML = "<span class=\"badge badge-dim alert-success\">Step 2 Completed</span>";
        var tout, tint, minutes_left;
        if (this.state.user.camera_state && this.state.captured && this.state.left > 0) {
          //decrease every minute
    var wait = document.getElementById("wait");

    if(this.state.exam.platform=="Flexi")
      wait = document.getElementById("waitother");
          tint = setInterval(() => {
            wait.innerHTML = this.state.left + " minutes left";
            if (this.state.left <= 0) {
              wait.href = this.state.testLocation;
              wait.innerHTML = "Enter Now";
              clearInterval(tint);
            }
            --this.state.left;
          }, 60000);
        }
      }
    }, 1000);

    //Wait for 5 seconds to open verify screen
    setTimeout(() => {
      //if(status.value==("Photo Valid"+String.fromCharCode("10003")))
      if (filename.value) {
        this.state.file = filename.value;
        this.state.user.live_pic = filename.value;
        this.state.user.status = status.value;
        console.log(this.state.file);
        console.log(this.state.user.status);
        //ReactDOM.render(<VerifyStudent user={this.state.user} />,document.getElementById("verify"));
      }
      else {
        this.state.user.status = "Not Verified";
      }
      const step3 = document.getElementById("step3");
      step3.classList.remove("noactive");
      step3.classList.add("active");
    }, 5000);

    //TODO: The code below is currently assumed dead. Need to remove this
    /*if (window.navigator.msSaveBlob) {
        window.navigator.msSaveBlob(canvas.msToBlob(), "pic" + this.state.user.id);
    } else {}*/
  }
  onOpenCvReady() {
    document.getElementById('errorMsg').innerHTML = 'OpenCV.js is ready.';
    console.log("opencv is ready");
  }

  toggleUserMenu() {
    this.setState({ userMenu: !this.state.userMenu });
  }
  toggleMenu() {
  //  this.setState({ menu: !this.state.menu });
  }
  toLocal = (utctime) => { var x = new Date(Date.parse("1970-01-01T" + utctime.toString() + "Z")); x += this.state.user.timezone * 60000; return (x.toLocaleString().slice(16, 24)); }
  //standard function. Assign value to name
  handleInput = (event) => {
    event.preventDefault();
    let nam = event.target.name;
    let val = event.target.value;
    this.setState({
      [nam]: val
    });
    if (nam == "exam_id")
      console.log(this.state.exam_id)
  }
  async getExam(event) {
    event.preventDefault();
    var api = "exam-api/exam/" + this.state.exam_id;
    const response = await Http.get(api);
    console.log(response.data);
    localStorage.setItem('exam1', JSON.stringify(response.data));
    this.setState({ exam_found: 1, exam: response.data });
  }
  handleSubmit(event) {
    event.preventDefault();
    this.setState({ exam_found: 1 })
    this.getExam();
  }
  handleTranscriptChange = (newTranscript) => {
    const audioMsgElement = document.querySelector('span#audioMsg');
    this.setState({ transcript: newTranscript });
    if (newTranscript.toLowerCase().includes("mike testing")) {
       audioMsgElement.innerHTML = "<span className=\"badge badge-dim alert-success\"> Step 3 Completed</span>";
    // Check if the transcript contains "Mike testing"
      console.log("Mike testing detected!");
      // You can perform further actions here
    }
  };

  VerifyOtp = () => {
    const errorMsgElement = document.querySelector('span#errorMsg');
    const OTPMsgElement = document.querySelector('span#OTPMsg');
    const step2 = document.getElementById("step2");
    const envstep2 = document.getElementById("envstep2");
    //const countstep2=document.getElementById("countstep2");
    const step3 = document.getElementById("step3");
    const wait = document.getElementById("wait");
    OTPMsgElement.innerHTML = "Verifying";
    Http.get('user-api/userv/' + this.state.user.email + '/' + this.state.verifyotp)
      .then(res => {
        const response = res.data;
        this.setState({
          user: response
        });
        OTPMsgElement.innerHTML = "OTP Verified";
        errorMsgElement.classList.add("badge-outline-success");
        errorMsgElement.classList.remove("badge-outline-danger");
        errorMsgElement.innerHTML = "Verification Successful Offline";
        console.log("OTP Verified");
        this.state.step1text = "Step1 Successful Offline";
        this.setState({ camera_state: true });
        step2.classList.remove("noactive");
        step2.classList.add("active");
        envstep2.classList.add("active");
        envstep2.classList.remove("noactive");
        wait.classList.remove("noactive");
        wait.classList.add("active");
        step3.classList.remove("noactive");
        step3.classList.add("active");
      })
      .catch(function (error) {
        OTPMsgElement.innerHTML = "OTP Verification failed";
        console.log(error);
      });

  }
  render() {
    if(this.state.isLogged) {
    const show = this.state.menu ? "active" : "noactive";
    const showMenu = this.state.menu ? "content-active" : "noactive";
    const usershow = this.state.userMenu ? "show" : "";
    return (
      <div>
        <meta charSet="utf-8" />
        <meta name="author" content="Softnio" />
        <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
        <meta name="description" content="@@page-discription" />
        {/* Fav Icon  */}
        <link rel="shortcut icon" href="/images/favicon.png" />
        {/* Page Title  */}
        <title>Assessment Report | Monitor Exam</title>
        {/* StyleSheets  */}
        <link rel="stylesheet" href="/assets/css/style.css" />
        <link rel="stylesheet" href="/assets/css/custom.css" />
        <link id="skin-default" rel="stylesheet" href="/assets/css/theme.css?ver=1.2.0" />
        <div className="nk-app-root">
          {/* main @s */}
          <div className="nk-main ">
            {/* wrap @s */}
            <div className="nk-wrap ">
              <div className="nk-header nk-header-fixed is-light">
                <div className="container-lg wide-xl">
                  <div className="nk-header-wrap">
                    <div className="nk-header-brand">
                      <a href="/" className="logo-link">
                        <img className="logo-dark logo-img" src="/images/monitor-exam-logo.png" srcSet="/images/monitor-exam-logo-2x.png 2x" alt="logo-dark" />
                      </a>
                    </div>{/* .nk-header-brand */}
                    <div className="nk-header-tools">
                      <ul className="nk-quick-nav">
                        <li onClick={this.toggleUserMenu} className={"dropdown user-dropdown " + usershow}>
                          <a href="#" className="dropdown-toggle" data-toggle="dropdown" aria-expanded={this.state.userMenu}>
                            <div className="user-toggle">
                              <div className="user-avatar sm">
                                <img src={'https://d1bumj8k6ax755.cloudfront.net/uploads/' + this.state.user.reg_pic.split("'")[1]} class="regpic " alt="reg" style={{ transform: "scale(0.5)", left: "6px" }} />
                                {/*<img src={this.state.user.live_pic} style={{height:'20px',width:'20px',border:'none',draggable:'true', borderRadius:"5px",backgroundColor:'lightGray'}}></img >*/}
                                <em className="icon ni ni-user-alt" />
                              </div>
                              <div className="user-name dropdown-indicator d-none d-sm-block">{this.state.user.first_name} {this.state.user.last_name}</div>
                            </div>
                          </a>
                          <div className={"dropdown-menu dropdown-menu-md dropdown-menu-right dropdown-menu-s1 " + usershow}>
                            <div className="dropdown-inner user-card-wrap bg-lighter d-none d-md-block">
                              <div className="user-card">
                                <div className="user-avatar">
																	<span>{this.state.user.first_name.charAt(0).toUpperCase()+this.state.user.last_name.charAt(0).toUpperCase()}</span>
                                </div>
                                <div className="user-info">
                                  <span className="lead-text">{this.state.user.first_name} {this.state.user.last_name}</span>
                                  <span className="sub-text">{this.state.user.email}</span>
                                </div>
                                <div className="user-action">
                                  <a className="btn btn-icon mr-n2" href="#"><em className="icon ni ni-setting" /></a>
                                </div>
                              </div>
                            </div>
                            <div className="dropdown-inner">
                              <ul className="link-list">
                                <li><a href="\viewprofile"><em className="icon ni ni-user-alt" /><span>View Profile</span></a></li>
                                <li><a href="/security"><em className="icon ni ni-setting-alt" /><span>Security Setting</span></a></li>
                                <li><a href="#"><em className="icon ni ni-activity-alt" /><span>Login Activity</span></a></li>
                              </ul>
                            </div>
                            <div className="dropdown-inner">
                              <ul className="link-list">
                                <li><a href="\newlogin"><em className="icon ni ni-signout" /><span onClick={() => localStorage.clear()}>Sign Out</span></a></li>
                              </ul>
                            </div>
                          </div>
                        </li>{/* .dropdown */}
                        <li onClick={this.toggleMenu} className={this.state.menu?"d-lg-none":"d-none"}>
                          <a href="#" className={"toggle nk-quick-nav-icon mr-n1 " + this.state.menu ? "active" : ""} data-target="sideNav"><em className="icon ni ni-menu" /></a>
                        </li>
                      </ul>{/* .nk-quick-nav */}
                    </div>{/* .nk-header-tools */}
                  </div>{/* .nk-header-wrap */}
                </div>{/* .container-fliud */}
              </div>
              {/* main header @e */}
              {/* content @s */}
              <div className="nk-content">
                <div className="container wide-xl">
                  <div className="nk-content-inner">
                    <div className={"nk-aside " + showMenu} data-content="sideNav" data-toggle-overlay="true" data-toggle-screen="lg" data-toggle-body="true">
                      <div className="nk-sidebar-menu" data-simplebar>
                        {/* Menu */}
                        <ul className="nk-menu">
                          <li className={"nk-menu-item " + ((this.state.user.role == "invig") ? "active" : "noactive")}>
                            <a href="/newdashboard" className="nk-menu-link">
                              <span className="nk-menu-icon"><em className="icon ni ni-users" /></span>
                              <span className="nk-menu-text">Student Dashboard</span>
                            </a>
                            <ul className="nk-menu-sub">
                              <li className="nk-menu-item "><a href="\livestudentdash" className="nk-menu-link"><span className="nk-menu-text">Live Students</span></a></li>
                              <li className="nk-menu-item "><a href="\newdashboard" className="nk-menu-link"><span className="nk-menu-text">All Students</span></a></li>
		            </ul>
                          </li>
                          <li className="nk-menu-item active">
                            <a href="/testdash" className="nk-menu-link">
                              <span className="nk-menu-icon"><em className="icon ni ni-file-docs" /></span>
                              <span className="nk-menu-text">Exam Dashboard</span>
                            </a>
                            <ul className="nk-menu-sub">
                              <li className="nk-menu-item"><a href="\flexi" className="nk-menu-link"><span className="nk-menu-text">Flexi Exams</span></a></li>
                              <li className="nk-menu-item"><a href="\unscheduled" className="nk-menu-link"><span className="nk-menu-text">Anytime Exams</span></a></li>
                            </ul>
                          </li>
                          <li className="nk-menu-item">
                            <a href="\newlogin" className="nk-menu-link">
                              <span className="nk-menu-icon"><em className="icon ni ni-signout" /></span>
                              <span onClick={() => localStorage.clear()} className="nk-menu-text">Log Out</span>
                            </a>
                          </li>
                        </ul>
                      </div>{/* .nk-sidebar-menu */}
                      <div className="nk-aside-close">
                        <a href="#" className="toggle" data-target="sideNav"><em className="icon ni ni-cross" /></a>
                      </div>{/* .nk-aside-close */}
                    </div>{/* .nk-aside */}
                    <div className="nk-content-body">
                      <div className="nk-content-wrap">
                        <div className="nk-block-head nk-block-head-lg d-none">
                          <div className="nk-block-between-md g-4">
                            <div className="nk-block-head-content">
                              <h2 className="nk-block-title" >Student Report</h2>
                              <div className="nk-block-des">
                                <p>Detailed Report of Assessment</p>
                              </div>
                            </div>
                          </div>
                        </div>{/* .nk-block-head */}
                        <div className="nk-block" style={{ transform: "0.8" }}>
                          <div className="card card-bordered">
                                  <>
                                       {/* Check if the users array is not empty */}
                                        {this.state.users.length > 0 ? (
                                        this.state.users.filter(user => (user.id == this.state.user_id )).map((user, index) => (
                                        <p key={index}>{user.first_name}</p>
                                        ))
                                        ) : (
                                        <p>No users found</p>
                                        )}
                                   </>
                            <h4 class="text-primary text-center mb-4">Candidate Assessment Report</h4>
                            <div className="row step-card">
                                <div id="step1" className="col-sm-4 verify-envireonment ">
                                <div className="card card-bordered p-2 mb-2 ">
                                  <span className="text-center mb-2">Candidate Details</span>
                                  <p> <span id="errorMsg" className="badge badge-dim badge-outline-danger"></span></p>
                                  {/*<div><button id="startbutton" class="btn btn-lg btn-primary btn-block">Capture</button></div>*/}
                                  <input type="hidden" name="filename" id="filename" />
                                  <input type="hidden" name="fname" id="fname" />
	                            	<img src={'http://d1bumj8k6ax755.cloudfront.net/uploads/'+this.state.user.live_pic.split("'")[1]} className="exam-card-img" alt="profile-pic" style={{ maxWidth: "100%", height: "auto" }} />
                                  <span className="lead-text">{this.state.user.first_name} {this.state.user.last_name}</span>
                                  <span className="sub-text">{this.state.user.email}</span>
                                  <div className="" style={{display:"none"}}>
                                    <canvas id="canvas" width="320px" height="240px" style={{ display: this.state.captured ? "block" : "none", backgroundColor: "lightgray" }} > < /canvas>
                                      <a href="#step2" id="capture snap verification-start" className="btn btn-primary mt-2" variant="primary" placeholder='Capture' style={{ display: this.state.captured ? "none" : "block" }} disabled={!this.state.camera_state} required onClick={this.handleCapture} > {this.state.step1text} < /a>
                                      <input type="readonly" className="text-center mb-2" name="status" id="status" readonly />
                                        <div style={{ display: this.state.camera_state ? "none" : "block" }}>
                                          <div class="form-control-wrap">
                                            <label for="verifyotp" className="mb-0 fs-8">Chat with invigilator to get OTP. Enter OTP</label>
                                            <p> <span id="OTPMsg" className="small alert-warning"></span></p >
                                            <input type="text" className="text-center mb-2 form-control form-control-lg" name="verifyotp" id="verifyotp" placeholder="Enter OTP received from invigilator in chat" onChange={this.handleChange} />
                                          </div>
                                          <button class="btn btn-md btn-primary btn-block" onClick={this.VerifyOtp}>Verify OTP</button>
                                        </div>
                                      </div>
                                      <span className="badge badge-dim badge-outline-success auth-animated-alert">Please wait, It takes 5 seconds to verify</span>
                                      {/*<a href="#" class="btn btn-primary btn-block mb-2">Capture</a>
                                                        <img src="images/avatar/b-sm.jpg" class="mb-2">
                                                        <span class="badge badge-simple text-center">Photo Valid</span>*/}
                                  </div>
                                </div>
                                <div id="step2" className="col-sm-4  verify-envireonment ">
                                  <div className="card card-bordered p-2 mb-2">
                                    <span className="text-center mb-2">Enviroment Details</span>
                                    <p> <span id="timeMsg"> </span></p >
                                    <iframe src={
                                      "https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d14033.35944807659!2d" + this.state.user.lng + "!3d" + this.state.user.lat + "!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sin!4v1606743989630!5m2!1sen!2sin"
                                    } width="240px" height="320px" frameborder="0" style={{ border: "0" }}>
                                    </iframe>
                                    <div id="envstep2" className="environment-info noactive">
                                      <p><em className="icon ni ni-camera text-primary" /> .... <span id="cameraMsg" className="ok status"><em className="icon ni ni-check" /></span> </p>
                                      <p><em className="icon ni ni-spark text-primary" /> .... <span id="baMsg" className="ok status"><em className="icon ni ni-check" /></span> <span className="status"></span></p>
                                      <p><em className="icon ni ni-speed text-primary" /> .... <span id="nwMsg" className="ok status"><em className="icon ni ni-check" /></span> <span className="status"></span></p>
                                      <p><em className="icon ni ni-location text-primary" /> .... <span id="ipMsg" className="ok status"><em className="icon ni ni-check" /></span> <span className="status"></span></p>
                                      <p><em className="icon ni ni-monitor text-primary" /> .... <span id="mMsg" className="ok status"><em className="icon ni ni-check" /></span> <span className="status"></span></p>
                                      <p><em className="icon ni ni-file-text text-primary" /> .... <span id="sMsg" className="ok status"><em className="icon ni ni-check" /></span> <span className="status"></span></p>

                                    </div>
                                    <a href="#step3" className="btn btn-primary btn-block" id="update-state">Next</a>
                                    <span id="errorMsg3" className="badge badge-dim badge-outline-success auth-animated-alert-state">Please wait, It takes 5 seconds to verify</span>
                                  </div>
                                </div>
                                <div id="step3" className="col-sm-4 step-3 noactive">
                                  <div className="card card-bordered p-2">
                                    <span className="text-center mb-2 ">Step 3: Audio Verification</span>
                                    <p><span id="audioMsg" className="badge badge-dim alert-success"></span></p>
                                    <div className="row">
                                      <div className="exam-entry col-sm-12" style={{display:this.state.exam.platform=="Flexi"?"none":""}}>
	    {/*<SpeechRecognitionComponent />*/}
                                        <SpeechRecognition onTranscriptChange={this.handleTranscriptChange} />
                          	            <p>Transcript: {this.state.transcript!='' ?    (this.state.transcript.toLowerCase().includes("mike testing"))?"Audio OK":"Audio Test Failed":this.state.transcript} 

      </p>

      <p className="alert-info">If there is still time left in starting the exam, please wait here. When the time is right this button will become <em>Enter Now</em> button</p>
                                      </div>
                                      <div className="exam-entry col-sm-12" style={{display:this.state.exam.platform=="Flexi"?"":"none"}}>
                                        <SpeechRecognition onTranscriptChange={this.handleTranscriptChange} />
                          	            <p>Transcript: {this.state.transcript!='' ?    (this.state.transcript.toLowerCase().includes("mike testing"))?"Mike working":"Audio Test Failed":this.state.transcript} 
      </p>
                                        <a id="waitother" href={(this.state.left <= 0)&&(this.state.exam.platform=="Flexi") ? (((Date.parse(this.state.exam.window_start_date) + Date.parse("1970-01-01T" + this.state.exam.window_start_time.toString() + "Z") + this.state.exam.window_duration * 60000) > (Date.now())) ? this.state.testLocation : '#') : '#'} className="btn btn-primary mb-3 mt-3 nextpage noactive">
                                          {(this.state.left <= 0) && (this.state.exam.platform=="Flexi")? (((Date.parse(this.state.exam.window_start_date) + Date.parse("1970-01-01T" + this.state.exam.window_start_time.toString() + "Z") + this.state.exam.window_duration * 60000) > (Date.now())) ? "Enter Now" : "Sorry You are Late for exam") : this.state.left + " minutes left"}</a>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="card card-bordered p-2 noactive">
                                    <span className="text-center mb-2 noactive">Step 3: Upload ID Card</span>
                                    <p><span id="admitMsg" className="badge badge-dim alert-success"></span></p>
                                    <div className="upload-zone noactive">
                                      <div className="dz-message" data-dz-message>
                                        <span className="dz-message-text">Drag and drop file</span>
                                        <span className="dz-message-or">or</span>
                                        <a className="btn btn-primary">SELECT</a>
                                      </div>
                                    </div>
                                    <label className="btn btn-primary btn-block mb-2" id="IDCard" for="upload" style={{ display: !this.state.fileAdmit ? "block" : "none", textAlign: "center", backgroundColor: "#6576ff", color: "white", align: "right", border: "none", borderRadius: "4px" }} size="md" required > Upload ID Card < /label>
                                      <input id="upload" type='file' name='fileAdmit' disabled={!this.state.captured} accept="image/*" onChange={this.uploadAdmitCard} />
                                      <img src={this.state.fileAdmit} accept="image/ * " id='imageAdmit' style={{ height: '320px', width: '240px', border: 'none', draggable: 'true', display: this.state.fileAdmit ? "block" : "none", backgroundColor: 'lightGray' }}></img>
                                      <div className="row" style={{ display: this.state.fileAdmit ? "block" : "none" }}>
                                        <div className="col-sm-12 text-right">
                                        </div>
                                      </div>
                                      <object data={this.state.fileAdmit} type="application/pdf" width="240px" height="0px" >
                                        <p style={{ display: "none" }} > Alternative text - include a link < a href="myfile.pdf" > to the PDF! < /a></p >
                                      </object>
                                      <span className="badge badge-dim badge-outline-success auth-animated-alert-admit-card">Please wait, It takes 5 seconds to verify</span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* footer @e */}
                      </div>
                    </div>
                  </div>
                </div>
                {/* content @e */}
              </div>
              {/* wrap @e */}
            </div>
            {/* main @e */}
          </div>
          {/* app-root @e */}
          {/* JavaScript */}
        </div>
        );
    } else {
      return(<NewLogin />);
    }

  }
}

        export default Report








