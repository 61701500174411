import React, {
	Component,
	useState,
	useEffect
} from 'react'
import {
	Redirect
} from 'react-router-dom'
import NewLogin from '../Auth/NewLogin'
import ExamCardDashboard from '../Dashboard/ExamCardDashboard'
class SDGMat extends React.Component {
	constructor() {
		super();
		this.state = {
      isLogged: false,
      isLoaded: false,
			userMenu: false,
			menu: false,
		};
		this.toggleMenu = this.toggleMenu.bind(this);
		this.toggleUserMenu = this.toggleUserMenu.bind(this);
	}
	toggleUserMenu() {
		this.setState({ userMenu: !this.state.userMenu });
	}
	toggleMenu() {
	//	this.setState({ menu: !this.state.menu });
	}
	componentWillMount() {
		const user = JSON.parse(localStorage.getItem('user'));
    if (this.state.user = user) {
			this.state.user = user;
      this.state.isLogged = true;
    }
    else {
      console.log("User not Logged in");
      if (this.props.user) {
        this.state.user = this.props.user;
        this.state.isLogged = true;
      }
      else
        this.state.isLogged = false;
    }
	}
	render() {
    if(this.state.isLogged) {
		const show = this.state.menu ? "active" : "noactive";
		const showMenu = this.state.menu ? "content-active" : "noactive";
		const usershow = this.state.userMenu ? "show" : "";
		return (
			<div>
				<meta charSet="utf-8" />
				<meta name="author" content="Softnio" />
				<meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
				<meta name="description" content="@@page-discription" />
				{/* Fav Icon  */}
				<link rel="shortcut icon" href="/images/favicon.png" />
				{/* Page Title  */}
				<title>Verification Room | Monitor Exam</title>
				{/* StyleSheets  */}
				<link rel="stylesheet" href="assets/css/style.css" />
				<link rel="stylesheet" href="assets/css/custom.css" />
				<link id="skin-default" rel="stylesheet" href="assets/css/theme.css?ver=1.2.0" />
				<div className="nk-app-root">
					{/* main @s */}
					<div className="nk-main ">
						{/* wrap @s */}
						<div className="nk-wrap ">
							<div className="nk-header nk-header-fixed is-light">
								<div className="container-lg wide-xl">
									<div className="nk-header-wrap">
										<div className="nk-header-brand">
											<a href="#" className="logo-link">
												<img className="logo-dark logo-img" src="/images/monitor-exam-logo.png" srcSet="/images/monitor-exam-logo-2x.png 2x" alt="logo-dark" />
											</a>
										</div>{/* .nk-header-brand */}
										<div className="nk-header-tools">
											<ul className="nk-quick-nav">
												<li onClick={this.toggleUserMenu} className={"dropdown user-dropdown " + usershow}>
													<a href="#" className="dropdown-toggle" data-toggle="dropdown" aria-expanded={this.state.userMenu}>
														<div className="user-toggle">
															<div className="user-avatar sm">
                                <img src={'https://d1bumj8k6ax755.cloudfront.net/uploads/' + this.state.user.reg_pic.split("'")[1]} class="regpic " alt="reg" style={{ transform: "scale(0.5)", left: "6px" }} />
																<em className="icon ni ni-user-alt" />
															</div>
															<div className="user-name dropdown-indicator d-none d-sm-block">{this.state.user.first_name} {this.state.user.last_name}</div>
														</div>
													</a>
													<div className="dropdown-menu dropdown-menu-md dropdown-menu-right dropdown-menu-s1">
														<div className="dropdown-inner user-card-wrap bg-lighter d-none d-md-block">
															<div className="user-card">
																<div className="user-avatar">
																	<span>{this.state.user.first_name.charAt(0).toUpperCase()+this.state.user.last_name.charAt(0).toUpperCase()}</span>
																</div>
																<div className="user-info">
																	<span className="lead-text">{this.state.user.first_name} {this.state.user.last_name}</span>
																	<span className="sub-text">{this.state.user.email}</span>
																</div>
																<div className="user-action">
																	<a className="btn btn-icon mr-n2" href="#"><em className="icon ni ni-setting" /></a>
																</div>
															</div>
														</div>
														<div className="dropdown-inner">
															<ul className="link-list">
																<li><a href="#"><em className="icon ni ni-user-alt" /><span>View Profile</span></a></li>
																<li><a href="/security"><em className="icon ni ni-setting-alt" /><span>Security Setting</span></a></li>
																<li><a href="#"><em className="icon ni ni-activity-alt" /><span>Login Activity</span></a></li>
															</ul>
														</div>
														<div className="dropdown-inner">
															<ul className="link-list">
																<li><a href="#"><em className="icon ni ni-signout" /><span>Sign out</span></a></li>
															</ul>
														</div>
													</div>
												</li>{/* .dropdown */}
                <li onClick={this.toggleMenu} className={this.state.menu?"d-lg-none":"d-none"}>
													<a href="#" className={"toggle nk-quick-nav-icon mr-n1 " + this.state.menu ? "active" : ""} data-target="sideNav"><em className="icon ni ni-menu" /></a>
												</li>
											</ul>{/* .nk-quick-nav */}
										</div>{/* .nk-header-tools */}
									</div>{/* .nk-header-wrap */}
								</div>{/* .container-fliud */}
							</div>
							{/* main header @e */}
							{/* content @s */}
							<div className="nk-content ">
								<div className="container wide-xl">
									<div className="nk-content-inner">
<div className={"nk-aside " + showMenu} data-content="sideNav" data-toggle-overlay="true" data-toggle-screen="lg" data-toggle-body="true">
											<div className="nk-sidebar-menu" data-simplebar>
												{/* Menu */}
												<ul className="nk-menu">
													<li className="nk-menu-heading d-none">
														<h6 className="overline-title">Menu</h6>
													</li>
													<li className="nk-menu-item active">
														<a href="/testdash" className="nk-menu-link">
															<span className="nk-menu-icon"><em className="icon ni ni-file-docs" /></span>
															<span className="nk-menu-text">Exam Dashboard</span>
														</a>
														<ul className="nk-menu-sub">
															<li className="nk-menu-item"><a href="/testdash" className="nk-menu-link"><span className="nk-menu-text">Ongoing Exams</span></a></li>
															<li className="nk-menu-item"><a href="/upcoming" className="nk-menu-link"><span className="nk-menu-text">Upcoming Exams</span></a></li>
                              <li className="nk-menu-item"><a href="\flexi" className="nk-menu-link"><span className="nk-menu-text">Flexi Exams</span></a></li>
															<li className="nk-menu-item"><a href="/unscheduled" className="nk-menu-link"><span className="nk-menu-text">Unscheduled Exams</span></a></li>
															<li className="nk-menu-item"><a href="/pastexamdash" className="nk-menu-link"><span className="nk-menu-text">Past Exams </span></a></li>
														</ul>
													</li>
													<li className="nk-menu-item">
														<a href="\newlogin" className="nk-menu-link">
															<span className="nk-menu-icon"><em className="icon ni ni-signout" /></span>
															<span className="nk-menu-text" onClick={() => localStorage.clear()}>Sign Out</span>
														</a>
													</li>
												</ul>
											</div>{/* .nk-sidebar-menu */}
											<div className="nk-aside-close">
												<a href="#" className="toggle" data-target="sideNav"><em className="icon ni ni-cross" /></a>
											</div>{/* .nk-aside-close */}
										</div>{/* .nk-aside */}
										<div className="nk-content-body">
											<div className="nk-content-wrap">
												<div className="nk-block-head nk-block-head-lg">
													<div className="nk-block-between-md g-4">
														<div className="nk-block-head-content">
															<h2 className="nk-block-title pt-70"></h2>
														</div>
													</div>
												</div>{/* .nk-block-head */}
												<div className="nk-block">
													<div className="card card-bordered verification-box room">
														<div className="row">
															<div className="col-sm-3 step-1">
																<div className="card card-bordered p-2 mb-2">
																	<span className="text-center mb-2">SDG Exam Prep Material</span>
																</div>
															</div>
													  </div>
														<div className="row">
															<div className="col-sm-12 step-2">
																<div className="card card-bordered p-2 mb-2">
                                   <embed src="https://sdg.monitorexam.com/sdglv34" style={{height:"800px", width:"100%"}}></embed>
																</div>
															</div>
														</div>
														<div className="row">
															<div className="col-sm-12 step-2">
																<div className="card card-bordered p-2 mb-2">
                            <ExamCardDashboard />
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
											{/* footer @e */}
										</div>
									</div>
								</div>
							</div>
							{/* content @e */}
						</div>
						{/* wrap @e */}
					</div>
					{/* main @e */}
				</div>
				{/*footer*/}
				{/* app-root @e */}
				{/* JavaScript */}
			</div>
		);
	}
    else 
      <NewLogin />
  }
}
export default SDGMat
