import React, { useState } from 'react';
import Http from 'axios';

const FileUpload = () => {
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
    handleSubmit();
  };

  const handleSubmit = async (event) => {
    //event.preventDefault();
    
    if (!file) {
      setMessage('Please upload a CSV file.');
      return;
    }

    const formData = new FormData();
    formData.append('file', file);

    try {
      setLoading(true);
      const response = await Http.post('user-api/uploadcsv', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      setMessage('File uploaded successfully!');
      console.log(response.data);
    } catch (error) {
      setMessage('Error uploading file.');
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <label className="btn btn-primary-outline btn-block mb-2" id="IDCard" for="upload" style={{ textAlign: "center", backgroundColor: "#6576ff", color: "white", align: "right", border: "none", borderRadius: "4px" }} size="md" required > Select CSV File < /label>
        <input id="upload" type='file' name='fileAdmit' style={{display:"none"}} accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" onChange={handleFileChange} />
        <button class="btn btn-primary btn-block" type="submit" disabled={loading}>
          {loading ? 'Uploading...' : 'Upload'}
        </button>
      </form>
      {message && <p>{message}</p>}
    </div>
  );
};

export default FileUpload;
