
import React from 'react'
import ReactDOM from 'react-dom'
import Jumbotron from 'react-bootstrap/Jumbotron'
import ReactGA from 'react-ga';
import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'
import ListGroup from 'react-bootstrap/ListGroup'
    class WFPricing extends React.Component {
	constructor(){
		super();
		// Add your tracking ID created from https://analytics.google.com/analytics/web/#home/
		ReactGA.initialize('UA-53038418-2');
		// This just needs to be called once since we have no routes in this case.
		ReactGA.pageview(window.location.pathname);
		this.state = {
                status: false,
		open:false
            };
	}
  render() {
    return (
      <div>
        {/*  Last Published: Mon Feb 19 2024 04:04:46 GMT+0000 (Coordinated Universal Time)  */}
        <meta charSet="utf-8" />
        <title>Pricing</title>
        <meta content="The pricing page for MonitorExam" name="description" />
        <meta content="Pricing" property="og:title" />
        <meta content="The pricing of MonitorExam is based on the type of proctoring we are using. However if you need bulk pricing we can customize a deal for you." property="og:description" />
        <meta content="Pricing" property="twitter:title" />
        <meta content="The pricing of MonitorExam is based on the type of proctoring we are using. However if you need bulk pricing we can customize a deal for you." property="twitter:description" />
        <meta property="og:type" content="website" />
        <meta content="summary_large_image" name="twitter:card" />
        <meta content="width=device-width, initial-scale=1" name="viewport" />
        <link href="../assets/css/normalize.css" rel="stylesheet" type="text/css" />
        <link href="../assets/css/webflow.css" rel="stylesheet" type="text/css" />
        <link href="../assets/css/monitorexam-4f64b7a40832b476405c7c490d4.webflow.css" rel="stylesheet" type="text/css" />
        <style dangerouslySetInnerHTML={{__html: "@media (min-width:992px) {html.w-mod-js:not(.w-mod-ix) [data-w-id=\"9710b2f8-5205-2ac0-628d-617b351f967d\"] {opacity:0;}}" }} />
        <link href="https://fonts.googleapis.com" rel="preconnect" />
        <link href="https://fonts.gstatic.com" rel="preconnect" crossOrigin="anonymous" />
        <link href="../images/website/favicon.png" rel="shortcut icon" type="image/x-icon" />
        <link href="../images/website/webclip.png" rel="apple-touch-icon" />
        <div className="subpage-header pricing-2">
          <div className="container w-container">
            <h2 data-ix="fade-in-on-load" className="subpage-title">Pricing Options</h2>
            <div data-ix="fade-in-on-load-2" className="page-subtitle">Our Pricing is value based. We ensure that if you use MonitorExam the benefit in terms of saving time of resources will be much more than what we charge</div>
          </div>
          <div data-animation="default" className="navlink white link-2 w-nav" data-easing2="ease-in" data-easing="ease-out" data-collapse="all" role="banner" data-duration={400} data-doc-height={1} sr>
            <div className="w-container">
              <a href="/home" className="logo-block w-nav-brand"><img src="../images/website/monitor-exam-logox2.png" alt="" className="logo fixed link-2" /></a>
              <address role="navigation" className="nav-menu w-nav-menu">
                <a href="/product" className="product w-nav-link">PRODUCT</a>
                <a href="/about" className="about w-nav-link">ABOUT</a>
                <a href="/pricing" aria-current="page" className="pricing w-nav-link w--current">PRICING</a>
                <a href="https://talk.monitorexam.com" className="blog w-nav-link">BLOG</a>
                <a href="/contact" className="contact w-nav-link">CONTACT</a><button className="user-log-in-log-out" data-wf-user-logout="LOGOUT" data-wf-user-login="LOGIN" onClick={()=>window.location.href='/newLogin'} type="button">LOGIN</button>
              </address>
            </div>
            <div className="fixed-nav-menu-button w-nav-button" onClick={()=>this.setState({open:!this.state.open})}>
              <div className="icon w-icon-nav-menu" />
            </div>
          </div>
	  <div class="w-nav-overlay" data-wf-ignore="" id="w-nav-overlay-0" style={{height:this.state.open?"2846px": "0px", display:this.state.open?"block": "none"}}>
	      <address role="navigation" class="nav-menu w-nav-menu" style={{transform: "translateY(0px) translateX(0px)", transition: "transform 400ms ease-out 0s;"}} data-nav-menu-open="">
                <a href="/product" className="product w-nav-link w--nav-link-open">PRODUCT</a>
                <a href="/about" className="about w-nav-link w--nav-link-open">ABOUT</a>
                <a href="/pricing" className="pricing w-nav-link w--nav-link-open">PRICING</a>
                <a href="https://talk.monitorexam.com" className="blog w-nav-link w--nav-link-open">BLOG</a>
                <a href="/contact" className="contact w-nav-link w--nav-link-open">CONTACT</a>
	      <button className="user-log-in-log-out" data-wf-user-logout="LOGOUT" data-wf-user-login="LOGIN" onClick={()=>window.location.href='/newLogin'}type="button">LOGIN</button>
        </address></div>
        </div>
        <div className="section pricing-section">
          <div className="container w-container">
            <div className="pricing-page-row w-row">
              <div className="pricing-column w-col w-col-4">
                <div className="pricing-block">
                  <div className="pricing-title">AUTO+</div>
                  <div className="pricing-price">₹400</div>
                  <p className="pricing-paragraph">No proctor needed. Student can login and appear for the exam. A credibility score will be sent to the Test Administrator at the end of the exam</p>
                  <a href="contact" className="button pricing-button w-button">Get in touch now</a>
                </div>
              </div>
              <div className="pricing-column w-col w-col-4">
                <div className="pricing-block image">
                  <div className="pricing-title">VErify+</div>
                  <div className="pricing-block-tag">Best Choice</div>
                  <div className="pricing-price">₹500</div>
                  <p className="pricing-paragraph">Verify+ ensures that the test-taker is manually verified before entering the exam. After verification proctoring is same as Auto+</p>
                  <a href="contact" className="button pricing-button w-button">Get in touch now</a>
                </div>
              </div>
              <div className="pricing-column w-col w-col-4">
                <div className="pricing-block">
                  <div className="pricing-title">Live+</div>
                  <div className="pricing-price">₹1500</div>
                  <p className="pricing-paragraph">Live+ ensures that the proctor is available for verification and proctoring. Such exams are generally high-stake exams. </p>
                  <a href="contact" className="button pricing-button w-button">Get in touch now</a>
                </div>
              </div>
            </div>
            <div className="top-line-block">
              <div className="text-row w-row">
                <div className="text-column w-col w-col-3">
                  <div className="text-column-title">Questions? Contact us!</div>
                  <p className="small-paragraph">If you have a specific question which FAQs can't answer contact us and we will get back<br />
                    <a href="https://monitorexam.com/contact">Contact us now</a>
                  </p>
                </div>
                <div className="text-column w-col w-col-3">
                  <div className="text-column-title">Read our FAQs</div>
                  <p className="small-paragraph">You are bound to have questions. Please read our FAQ&nbsp;section for the same<br />
                    <a href="#">Read FAQs</a>
                  </p>
                </div>
                <div className="text-column w-col w-col-3">
                  <div className="text-column-title">The right Plan?</div>
                  <p className="small-paragraph">Don't feel that any of the above plan fits you. Contact us<br />
                    <a href="https://monitorexam.com/contact">Contact us now</a>
                  </p>
                </div>
                <div className="text-column w-col w-col-3">
                  <div className="text-column-title">Customized?</div>
                  <p className="small-paragraph">Have to conduct a large number of exams and have a customization request?<br />
                    <a href="https://docs.google.com/forms/d/e/1FAIpQLSfBVp4QFC34eOjgdfmesc7m5BflEdthtsYprjyOwjWwAebA0w/viewform" target="_blank" className="link-9">Get the right plan for you</a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="cf-faq-section-2">
          <div data-w-id="9710b2f8-5205-2ac0-628d-617b351f967d" className="cf-faq-main-wrapper horizontal">
            <div className="cf-faqs-text-wrap">
              <h2 className="cf-h2-heading left">Frequently asked questions</h2>
              <p className="cf-faq-paragraph">Answers to the burning questions in your mind.</p>
              <div className="cf-faq-bold-text">Have a different question?</div>
              <a href="#" data-w-id="9710b2f8-5205-2ac0-628d-617b351f9685" className="cf-call-button _24px-bold w-inline-block">
                <div className="cf-gradient-span-2">Contact us!</div>
                <div className="cf-call-arrow-contain-2 cf-gradient"><img src="https://uploads-ssl.webflow.com/6331e76dca4c150e44e353fc/undefined_Arrow%20-%20Right%20(4).svg" loading="lazy" alt="" className="cf-call-arrow-2" /><img src="https://uploads-ssl.webflow.com/6331e76dca4c150e44e353fc/undefined_Arrow%20-%20Right%20(4).svg" loading="lazy" alt="" className="cf-call-arrow-down-2 gradient" /></div>
              </a>
            </div>
            <div className="cf-faqs-wrapper">
              <div className="cf-faq-card">
                <div className="cf-faq-question-container-2 first">
                  <div className="cf-faq-question-wrapper-2">
                    <div className="cf-faq-questions">Do you have a free trial?<br /></div><img src="../images/website/plus.svg" loading="lazy" alt="" className="cf-faq-icon" />
                  </div>
                </div>
                <div className="cf-faq-answer-wrap">
                  <p className="cf-faq-content">Yes, we do have a free trial which we provide on request.</p>
                </div>
                <div className="cf-bottom-line" />
              </div>
              <div className="cf-faq-card">
                <div className="cf-faq-question-container-2">
                  <div className="cf-faq-question-wrapper-2">
                    <div className="cf-faq-questions">Do you have per student pricing?<br /></div><img src="../images/website/plus.svg" loading="lazy" alt="" className="cf-faq-icon" />
                  </div>
                </div>
                <div className="cf-faq-answer-wrap">
                  <p className="cf-faq-content">Yes, for further variations in pricing we can setup a call with you where we can closely understand your requirements and share pricing as per your needs/</p>
                </div>
                <div className="cf-bottom-line" />
              </div>
              <div className="cf-faq-card">
                <div className="cf-faq-question-container-2">
                  <div className="cf-faq-question-wrapper-2">
                    <div className="cf-faq-questions">What if I need just one test with few students<br /></div><img src="../images/website/plus.svg" loading="lazy" alt="" className="cf-faq-icon" />
                  </div>
                </div>
                <div className="cf-faq-answer-wrap">
                  <p className="cf-faq-content">No worries, we can customize a plan for you.</p>
                </div>
                <div className="cf-bottom-line" />
              </div>
              <div className="cf-faq-card">
                <div className="cf-faq-question-container-2">
                  <div className="cf-faq-question-wrapper-2">
                    <div className="cf-faq-questions">Do you give more discounts if we buy more?<br /></div><img src="../images/website/plus.svg" loading="lazy" alt="" className="cf-faq-icon" />
                  </div>
                </div>
                <div className="cf-faq-answer-wrap">
                  <p className="cf-faq-content">Yes, for bulk pricing we can give some discount from our marketing budget.</p>
                </div>
                <div className="cf-bottom-line" />
              </div>
              <div className="cf-faq-card">
                <div className="cf-faq-question-container-2">
                  <div className="cf-faq-question-wrapper-2">
                    <div className="cf-faq-questions">Where can I see some of your work?<br /></div><img src="../images/website/plus.svg" loading="lazy" alt="" className="cf-faq-icon" />
                  </div>
                </div>
                <div className="cf-faq-answer-wrap">
                  <p className="cf-faq-content">You can click on the schedule demo button and we can share a demo with you.</p>
                </div>
                <div className="cf-bottom-line" />
              </div>
            </div>
          </div>
        </div>
        <div className="simple-footer">
          <div className="container w-container">
            <div className="footer-row w-row">
              <div className="footer-logo-column-left w-col w-col-3">
                <div className="footer-logo">Monitorexam</div>
                <div className="footer-logo subtitle" />
              </div>
              <div className="footer-menu-column w-col w-col-6">
                <a href="index.html" className="footer-inline-link">Home</a>
                <a href="about.html" className="footer-inline-link">About us</a>
                <a href="pricing.html" aria-current="page" className="footer-inline-link w--current">Pricing</a>
                <a href="contact.html" className="footer-inline-link">Contact</a>
                <a href="https://talk.monitorexam.com" className="footer-inline-link">Blog</a>
              </div>
              <div className="footer-social-col-right w-col w-col-3">
                <a href="http://www.instagram.com/monitorexam" target="_blank" className="footer-social-icon w-inline-block"><img src="../images/website/Icon-facebook_3.png" alt="" className="footer-small-social-icon" /></a>
                <a href="https://www.twitter.com/monitorexam" target="_blank" className="footer-social-icon w-inline-block"><img src="../images/website/Icon-twitter.png" alt="" className="footer-small-social-icon" /></a>
                <a href="http://www.linkedin.com/company/innokreat" target="_blank" className="footer-social-icon w-inline-block"><img src="../images/website/Icon-google.png" alt="" className="footer-small-social-icon" /></a>
              </div>
            </div>
          </div>
          <div className="simple-bottom-footer">
            <div className="container w-container">
              <p className="bottom-footer-paragraph"><strong data-new-link="true">Copyright © Innokreat Technologies Pvt Ltd 2014-2024</strong>‍</p>
              <p className="bottom-footer-paragraph right" />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default WFPricing
